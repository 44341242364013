import React from "react";
import sections from "../../constants/data";
import SingleFeature from "./SingleFeature";
import "./Features.css";

const Features: React.FC = () => {
  return (
    <section className="features section-p bg-black" id="features">
      <div className="container">
        <div className="features-content">
          <div className="section-t text-center">
            <h3>FAQs</h3>
            <p className="text">
              Desde información sobre nuestros servicios y horarios hasta
              detalles sobre cómo inscribirse en clases, aquí encontrarás todo
              lo que necesitas saber para comenzar tu viaje en el mundo del
              baile con nosotros. Si tienes alguna otra pregunta que no se
              responda aquí, no dudes en ponerte en contacto con nuestro equipo,
              ¡estamos aquí para ayudarte!
            </p>
          </div>
          <div className="item-list text-white">
            {sections.features.map((feature) => {
              return <SingleFeature key={feature.id} {...feature} />;
            })}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Features;
