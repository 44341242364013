import React from "react";
// import { BsArrowRightCircle } from "react-icons/bs";
import "./Services.css";

interface Service {
  id: number;
  icon: JSX.Element;
  title?: string;
  text?: string;
}

const SingleService: React.FC<Service> = ({ id, icon, title, text }) => {
  return (
    <div className="item bg-dark translate-effect">
      <span className="item-icon">{icon}</span>
      <h4 className="item-title fs-25">{title}</h4>
      <div className="description-text">
        <p className="fs-19 text">{text}</p>
      </div>

      {/* <a href="/" className="item-link text-grey">
        <BsArrowRightCircle size={30} />
      </a> */}
    </div>
  );
};

export default SingleService;
