import React, { useState } from "react";
import { BsPlusLg } from "react-icons/bs";

interface Feature {
  id: number;
  title: string;
  text: string;
}

const gradient: string = "url(#blue-gradient)";

const SingleFeature: React.FC<Feature> = ({ id, title, text }) => {
  const [isActive, setIsActive] = useState<boolean>(false);

  return (
    <div className="item" key={id}>
      <div
        className={`item-head flex flex-between bg-dark ${
          isActive ? "item-head-border" : ""
        }`}
        onClick={() => setIsActive(!isActive)}
      >
        <h6 className="fs-20 fw-4 text-grey">{title}</h6>
        <button
          type="button"
          className={`item-icon ${isActive ? "item-icon-rotate" : ""}`}
        >
          <BsPlusLg style={{ fill: gradient }} size={22} />
        </button>
      </div>
      <div className={`item-body ${isActive ? "item-body-show" : ""}`}>
        <p className="text">{text}</p>
      </div>
    </div>
  );
};

export default SingleFeature;
