import React, {
  createContext,
  useContext,
  useEffect,
  useState,
  ReactNode,
} from "react";
import {
  getAuth,
  onAuthStateChanged,
  createUserWithEmailAndPassword,
  signOut,
  User as FirebaseUser,
  UserCredential,
  signInWithPopup,
} from "firebase/auth";
import { app, googleProvider, db } from "../../services/firebaseConfig";
import { doc, getDoc, setDoc } from "firebase/firestore";

interface AuthContextProps {
  currentUser: FirebaseUser | null;
  userRole: string | null;
  loginWithGoogle: () => Promise<UserCredential>;
  signup: (
    email: string,
    password: string,
    role: string
  ) => Promise<UserCredential>;
  logout: () => Promise<void>;
}

const AuthContext = createContext<AuthContextProps | undefined>(undefined);

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error("useAuth must be used within an AuthProvider");
  }
  return context;
};

const createUserDocument = async (user: FirebaseUser, role: string) => {
  const userDocRef = doc(db, "users", user.uid);
  const userData = {
    name: user.displayName,
    email: user.email,
    role: role,
  };

  try {
    await setDoc(userDocRef, userData, { merge: true });
    console.log("User document created/updated successfully.");
  } catch (error) {
    console.error("Error creating/updating user document.", error);
  }
};

const getUserRole = async (uid: string) => {
  const userDocRef = doc(db, "users", uid);
  const userDoc = await getDoc(userDocRef);
  if (userDoc.exists()) {
    return userDoc.data()?.role || "student";
  }
  return "student"; // Default role
};

const AuthProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [currentUser, setCurrentUser] = useState<FirebaseUser | null>(null);
  const [userRole, setUserRole] = useState<string | null>(null);
  const [loading, setLoading] = useState(true);
  const auth = getAuth(app);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        const role = await getUserRole(user.uid);
        console.log(`User Role: ${role}`);
        setUserRole(role);
        createUserDocument(user, role);
      }
      setCurrentUser(user);
      setLoading(false);
    });
    return unsubscribe;
  }, [auth]);

  const loginWithGoogle = () => {
    const provider = googleProvider;
    return signInWithPopup(auth, provider).then(async (result) => {
      const user = result.user;
      if (user) {
        const role = await getUserRole(user.uid);
        // createUserDocument(user, role);
        setUserRole(role);
      }
      return result;
    });
  };

  const signup = (email: string, password: string, role: string) => {
    return createUserWithEmailAndPassword(auth, email, password).then(
      (userCredential) => {
        if (userCredential.user) {
          createUserDocument(userCredential.user, role);
          setUserRole(role);
        }
        return userCredential;
      }
    );
  };

  const logout = () => {
    return signOut(auth).then(() => {
      setCurrentUser(null);
      setUserRole(null);
    });
  };

  const value: AuthContextProps = {
    currentUser,
    userRole,
    loginWithGoogle,
    signup,
    logout,
  };

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
