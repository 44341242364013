import React, { useEffect } from "react";
import Navbar from "../../components/Navbar/Navbar";
import sections from "../../constants/data";
import Footer from "../../components/Footer/Footer";
import { useLocation } from "react-router-dom";
import "./Regulation.css";

const Regulation: React.FC = () => {
  const location = useLocation();

  useEffect(() => {
    if (location.hash) {
      const targetElement = document.querySelector(location.hash);
      if (targetElement) {
        targetElement.scrollIntoView({ behavior: "smooth" });
      }
    } else {
      // Scroll to the top of the page if no hash is provided
      window.scrollTo({ top: 0, behavior: "smooth" });
    }
  }, [location.hash]);

  return (
    <>
      <div className="regulation section-p bg-dark" id="regulation">
        <Navbar />
        <div className="container">
          <h1 className="title text-grey text-center">
            {sections.regulation.title}
          </h1>
          <br />
          <p className="text text-justify">{sections.regulation.content}</p>
          <br />
          <ul>
            {sections.regulation.sections.map((section, index) => (
              <li key={index}>
                <h2 className="section-content text-grey text-center">
                  {section.title}
                </h2>
                <ul>
                  {section.content.map((item, index) => (
                    <li key={index}>
                      <p className="text text-justify">{item}</p>
                    </li>
                  ))}
                </ul>
                <br />
              </li>
            ))}
          </ul>
          <br />
          <p className="text text-end">{sections.regulation.lastUpdated}</p>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Regulation;
