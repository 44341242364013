import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import Privacy from "./pages/Privacy/Privacy";
import Regulation from "./pages/Regulation/Regulation";
import Pricing from "./pages/Pricing/Pricing";
import Contact from "./pages/Contact/Contact";
import Login from "./components/Auth/Login";
import AuthProvider, { useAuth } from "./components/Auth/AuthProvider";
import Signup from "./components/Auth/Signup";
import AdminDashboard from "./components/Dashboard/AdminDashboard";
import CalendarView from "./pages/Calendar/CalendarView";
import AdminClasses from "./pages/Admin/AdminClasses";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

const ProtectedRoute = ({ children }: { children: JSX.Element }) => {
  const { currentUser, userRole } = useAuth();
  if (!currentUser || userRole !== "admin") {
    console.log(currentUser);
    return <Navigate to="/" />;
  }
  return children;
};

root.render(
  <BrowserRouter>
    <AuthProvider>
      <Routes>
        <Route path="/" element={<App />}></Route>
        <Route path="/privacy" element={<Privacy />} />
        <Route path="/regulations" element={<Regulation />} />
        <Route path="/pricing" element={<Pricing />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/login" element={<Login />} />
        <Route path="/signup" element={<Signup />} />
        <Route
          path="/admin"
          element={
            <ProtectedRoute>
              <AdminDashboard />
            </ProtectedRoute>
          }
        />
        <Route
          path="/admin/classes"
          element={
            <ProtectedRoute>
              <AdminClasses />
            </ProtectedRoute>
          }
        />
        <Route path="/calendar" element={<CalendarView />} />
      </Routes>
    </AuthProvider>
  </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
