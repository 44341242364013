import React from "react";
import "./Contact.css";

const Map: React.FC = () => {
  return (
    <div className="map-content">
      {/* eslint-disable-next-line */}
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d134585.70077445003!2d-98.37652294810331!3d19.16676806054572!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x85d21d94d32c973d%3A0xac6a06a6f6c73705!2sML%20Dance%20Studio!5e0!3m2!1sen!2sus!4v1720411007783!5m2!1sen!2sus"
        width="75%"
        height="450"
        style={{ border: "0" }}
        allowFullScreen={true}
        loading="lazy"
        referrerPolicy="no-referrer-when-downgrade"
      ></iframe>
    </div>
  );
};

export default Map;
