import React from "react";
import "./Testimonials.css";
import { MdStar } from "react-icons/md";
import sections from "../../constants/data";

const gradient: string = "url(#blue-gradient)";

const Testimonials: React.FC = () => {
  let starList: JSX.Element[] = [];
  const showRating = (starCount: number) => {
    starList = [];
    for (let i = 0; i < starCount; i++) {
      starList.push(<MdStar key={i} size={25} style={{ fill: gradient }} />);
    }
    return starList;
  };

  return (
    <section className="testimonials section-p bg-black" id="testimonials">
      <div className="container">
        <div className="testimonials-content">
          <div className="section-t text-center">
            <h3>Nuestros Profesores</h3>
            <p className="text">
              Nos enorgullece contar con un equipo de profesores apasionados y
              altamente cualificados que son el corazón de nuestra comunidad del
              baile. Cada uno de nuestros profesores aporta un talento único,
              experiencia y dedicación a su enseñanza, creando un ambiente
              inspirador y enriquecedor para nuestros estudiantes. Desde el
              ballet clásico hasta el hip hop urbano, nuestros profesores están
              comprometidos a guiar, motivar y cultivar el talento de cada
              estudiante, brindando una experiencia de aprendizaje excepcional y
              fomentando el amor por el baile en cada clase.
            </p>
          </div>
          <div className="item-list grid text-white text-center">
            {sections.testimonials.map((testimonial) => {
              showRating(testimonial.rating);
              return (
                <div
                  className="item translate-effect bg-dark"
                  key={testimonial.id}
                >
                  <div className="item-img">
                    <img src={testimonial.image} alt="" />
                  </div>
                  <ul className="flex item-rating flex-center">
                    {starList.map((star, index) => {
                      return <li key={index}>{star}</li>;
                    })}
                  </ul>
                  <h4 className="item-name">{testimonial.name}</h4>
                  <p className="item-text text">{testimonial.text}</p>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Testimonials;
