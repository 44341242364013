import React from "react";
import ReactPlayer from "react-player";
import "./Video.css";

const Video: React.FC = () => {
  return (
    <section className="video section-p bg-black" id="video">
      <div className="container">
        <div className="video-content">
          <ReactPlayer
            url="https://www.youtube.com/watch?v=vuQS7A2PmR8"
            src="./VideoPromo.mp4"
            controls // Display video controls
            className="video-player"
          />
        </div>
      </div>
    </section>
  );
};

export default Video;
