import React, { useState } from "react";
import "./CardAction.css";

interface PricingProps {
  id: number;
  name: string;
  price: string;
  time: string;
  image?: string;
  content: string[];
}

const CardAction: React.FC<PricingProps> = ({
  name,
  price,
  time,
  image,
  content,
}) => {
  const [isFlipped, setIsFlipped] = useState(false);

  const handleFlip = () => {
    setIsFlipped(!isFlipped);
  };

  return (
    <div
      className={`card ${isFlipped ? "is-flipped" : ""}`}
      onClick={handleFlip}
    >
      <div className="bg-dark card-front">
        <div className="name">
          <p>{name}</p>
        </div>
        <div className="card-action-item-img">
          <img src={image} alt="" />
        </div>
        <div className="price">
          <p>{price}</p>
        </div>
      </div>
      <div className="bg-dark card-back">
        <ul>
          <li>{time} cargo a tarjeta</li>
          {content.map((item, index) => (
            <li key={index}>{item}</li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default CardAction;
