import header_main_img from "../assets/images/header_main_img.jpg";
import about_main_img from "../assets/images/about_main_img.jpg";
import form_main_img from "../assets/images/form_main_img.png";
import professionals_main_img from "../assets/images/professionals_main_img.jpg";
import video_cover_img from "../assets/images/video_cover_img.jpg";
import customer_img_1 from "../assets/images/customer_img_1.jpg";
import customer_img_2 from "../assets/images/customer_img_2.jpg";
import customer_img_3 from "../assets/images/customer_img_3.jpg";
import customer_img_4 from "../assets/images/customer_img_4.jpg";
import customer_img_5 from "../assets/images/customer_img_5.jpg";
import customer_img_6 from "../assets/images/customer_img_6.jpg";
import customer_img_7 from "../assets/images/customer_img_7.jpg";
import customer_img_8 from "../assets/images/customer_img_8.jpg";
import customer_img_9 from "../assets/images/customer_img_9.jpg";
import customer_img_10 from "../assets/images/customer_img_10.jpg";
import customer_img_11 from "../assets/images/customer_img_11.jpg";

import pricing_img_1 from "../assets/images/pricing_img_1.png";
import pricing_img_2 from "../assets/images/pricing_img_2.png";
import pricing_img_3 from "../assets/images/pricing_img_3.png";

import portfolio_img_1 from "../assets/images/portfolio_img_1.jpg";
import portfolio_img_2 from "../assets/images/portfolio_img_2.jpg";
import portfolio_img_3 from "../assets/images/portfolio_img_3.jpg";
import portfolio_img_4 from "../assets/images/portfolio_img_4.jpg";
import portfolio_img_5 from "../assets/images/portfolio_img_5.jpg";
import portfolio_img_6 from "../assets/images/portfolio_img_6.jpg";
import portfolio_img_7 from "../assets/images/portfolio_img_7.jpg";
import portfolio_img_8 from "../assets/images/portfolio_img_8.jpg";
import portfolio_img_9 from "../assets/images/portfolio_img_9.jpg";
import portfolio_img_10 from "../assets/images/portfolio_img_10.jpg";

import emblema_img_1 from "../assets/images/emblema_negativo_dorado.png";
import emblema_img_2 from "../assets/images/emblema_positivo_dorado.png";
import imagotipo_img_1 from "../assets/images/imagotipo_principal_negativo_dorado.png";
import imagotipo_img_2 from "../assets/images/imagotipo_principal_positivo_dorado.png";

interface Images {
  header_main_img: string;
  about_main_img: string;
  form_main_img: string;
  professionals_main_img: string;
  video_cover_img: string;
  customer_img_1: string;
  customer_img_2: string;
  customer_img_3: string;
  customer_img_4: string;
  customer_img_5: string;
  customer_img_6: string;
  customer_img_7: string;
  customer_img_8: string;
  customer_img_9: string;
  customer_img_10: string;
  customer_img_11: string;
  portfolio_img_1: string;
  portfolio_img_2: string;
  portfolio_img_3: string;
  portfolio_img_4: string;
  portfolio_img_5: string;
  portfolio_img_6: string;
  portfolio_img_7: string;
  portfolio_img_8: string;
  portfolio_img_9: string;
  portfolio_img_10: string;
  emblema_img_1: string;
  emblema_img_2: string;
  imagotipo_img_1: string;
  imagotipo_img_2: string;
  pricing_img_1: string;
  pricing_img_2: string;
  pricing_img_3: string;
}

const images: Images = {
  header_main_img,
  about_main_img,
  form_main_img,
  professionals_main_img,
  video_cover_img,
  customer_img_1,
  customer_img_2,
  customer_img_3,
  customer_img_4,
  customer_img_5,
  customer_img_6,
  customer_img_7,
  customer_img_8,
  customer_img_9,
  customer_img_10,
  customer_img_11,
  portfolio_img_1,
  portfolio_img_2,
  portfolio_img_3,
  portfolio_img_4,
  portfolio_img_5,
  portfolio_img_6,
  portfolio_img_7,
  portfolio_img_8,
  portfolio_img_9,
  portfolio_img_10,
  emblema_img_1,
  emblema_img_2,
  imagotipo_img_1,
  imagotipo_img_2,
  pricing_img_1,
  pricing_img_2,
  pricing_img_3,
};

export default images;
