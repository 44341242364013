import React, { useEffect, useState } from "react";
import moment from "moment-timezone";
import { useAuth } from "../../components/Auth/AuthProvider";
import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  getDoc,
  getDocs,
  Timestamp,
  DocumentReference,
  DocumentData,
} from "firebase/firestore";
import { db } from "../../services/firebaseConfig";
import Footer from "../../components/Footer/Footer";
import Navbar from "../../components/Navbar/Navbar";
import "./AdminClasses.css";
import {
  Class,
  DanceFloor,
  DanceLevel,
  DanceStyle,
  Instructor,
  Schedule,
} from "../../models";

const AdminClasses: React.FC = () => {
  const { userRole } = useAuth();
  const [classes, setClasses] = useState<Class[]>([]);
  const [instructors, setInstructors] = useState<Instructor[]>([]);
  const [danceFloors, setDanceFloors] = useState<DanceFloor[]>([]);
  const [danceStyles, setDanceStyles] = useState<DanceStyle[]>([]);
  const [danceLevels, setDanceLevels] = useState<DanceLevel[]>([]);
  const [newClass, setNewClass] = useState<Partial<Class>>({
    instructor_id: undefined as unknown as DocumentReference<DocumentData>,
    dance_style_id: undefined as unknown as DocumentReference<DocumentData>,
  });
  const [newSchedule, setNewSchedule] = useState<Partial<Schedule>>({
    start: Timestamp.fromDate(new Date()),
    end: Timestamp.fromDate(new Date()),
    dance_floor_id: undefined as unknown as DocumentReference<DocumentData>,
  });

  const [duration, setDuration] = useState<number>(60);
  const [selectedDays, setSelectedDays] = useState<string[]>([]);
  const [repeat, setRepeat] = useState<boolean>(false);
  const [month, setMonth] = useState<number>(new Date().getMonth() + 1);
  const [year, setYear] = useState<number>(new Date().getFullYear());

  const [filterName, setFilterName] = useState<string>("");
  const [filterInstructor, setFilterInstructor] = useState<string>("");
  const [filterDanceFloor, setFilterDanceFloor] = useState<string>("");
  const [filterStartDate, setFilterStartDate] = useState<string>("");
  const [filterEndDate, setFilterEndDate] = useState<string>("");

  const daysOfWeek = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];

  const fetchClasses = async () => {
    try {
      const classesCollection = collection(db, "classes");
      const classesSnapshot = await getDocs(classesCollection);
      const classesList = classesSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      })) as Class[];

      const updatedClasses = await Promise.all(
        classesList.map(async (classItem) => {
          const danceStyleRef = classItem.dance_style_id;
          let danceStyleName = "Unknown Dance Style";
          if (danceStyleRef) {
            const danceStyleDoc = await getDoc(danceStyleRef);
            if (danceStyleDoc.exists()) {
              danceStyleName = (danceStyleDoc.data() as DanceStyle).name;
            }
          }

          const instructorRef = classItem.instructor_id;

          let instructorName = "Unknown Instructor";
          if (instructorRef) {
            const instructorDoc = await getDoc(instructorRef);
            if (instructorDoc.exists()) {
              const instructorData = instructorDoc.data() as Instructor;
              instructorName = `${instructorData.name.first} ${instructorData.name.last}`;
            }
          }

          // Fetch schedules for this class
          const schedulesCollection = collection(
            db,
            `classes/${classItem.id}/schedules`
          );
          const schedulesSnapshot = await getDocs(schedulesCollection);
          const schedulesList = schedulesSnapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          })) as Schedule[];

          const updatedSchedules = await Promise.all(
            schedulesList.map(async (scheduleItem) => {
              const danceFloorRef = scheduleItem.dance_floor_id;
              let danceFloorName = "Unknown Dance Floor";
              if (danceFloorRef) {
                const danceFloorDoc = await getDoc(danceFloorRef);
                if (danceFloorDoc.exists()) {
                  const danceFloorData = danceFloorDoc.data() as DanceFloor;
                  danceFloorName = danceFloorData.name;
                }
              }

              return {
                ...scheduleItem,
                danceFloor: danceFloorName,
              };
            })
          );

          return {
            ...classItem,
            name: danceStyleName,
            instructor: instructorName,
            schedules: updatedSchedules,
          };
        })
      );

      setClasses(updatedClasses);
    } catch (error) {
      console.error("Error fetching classes:", error);
    }
  };

  const fetchInstructors = async () => {
    const instructorsCollection = collection(db, "instructors");
    const instructorsSnapshot = await getDocs(instructorsCollection);
    setInstructors(
      instructorsSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      })) as Instructor[]
    );
  };

  const fetchDanceFloors = async () => {
    const danceFloorsCollection = collection(db, "dance_floors");
    const danceFloorsSnapshot = await getDocs(danceFloorsCollection);
    setDanceFloors(
      danceFloorsSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      })) as DanceFloor[]
    );
  };

  const fetchDanceStyles = async () => {
    try {
      const danceStyleCollection = collection(db, "dance_style");
      const danceStyleSnapshot = await getDocs(danceStyleCollection);
      const danceStyleList = danceStyleSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      })) as DanceStyle[];

      setDanceStyles(danceStyleList);
    } catch (error) {
      console.error("Error fetching dance styles:", error);
    }
  };

  const fetchDanceLevels = async () => {
    try {
      const danceLevelCollection = collection(db, "levels");
      const danceLevelSnapshot = await getDocs(danceLevelCollection);
      const danceLevelList = danceLevelSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      })) as DanceLevel[];

      setDanceLevels(danceLevelList);
    } catch (error) {
      console.error("Error fetching dance levels: ", error);
    }
  };

  useEffect(() => {
    fetchDanceStyles();
    fetchDanceLevels();
    fetchClasses();
    fetchInstructors();
    fetchDanceFloors();
  }, []);

  const handleDanceStyleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = e.target;
    if (name === "dance_style_id") {
      setNewClass({ ...newClass, [name]: doc(db, "dance_style", value) });
    }
  };

  const handleInstructorChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = e.target;
    if (name === "instructor_id") {
      setNewClass({ ...newClass, [name]: doc(db, "instructors", value) });
    } else {
      setNewClass({ ...newClass, [name]: value });
    }
  };

  const handleDanceLevelChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = e.target;
    if (name === "dance_level_id") {
      setNewClass({ ...newClass, [name]: doc(db, "levels", value) });
    }
  };

  const handleDanceFloorChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = e.target;
    if (name === "dance_floor_id") {
      setNewSchedule({
        ...newSchedule,
        [name]: doc(db, "dance_floors", value),
      });
    }
  };

  const handleScheduleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = e.target;
    if (name === "start") {
      const dateInMexicoCity = moment
        .tz(value, "YYYY-MM-DDTHH:mm", "America/Mexico_City")
        .toDate();
      setNewSchedule({
        ...newSchedule,
        start: Timestamp.fromDate(dateInMexicoCity),
        end: Timestamp.fromDate(
          moment(dateInMexicoCity).add(duration, "minutes").toDate()
        ),
      });
    } else if (name === "duration") {
      setDuration(parseInt(value, 10));
      if (newSchedule.start) {
        const startDate = moment(newSchedule.start.toDate());
        const endDate = moment(startDate)
          .add(parseInt(value, 10), "minutes")
          .toDate();
        setNewSchedule({
          ...newSchedule,
          end: Timestamp.fromDate(endDate),
        });
      }
    }
  };

  const handleDaysChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value, checked } = e.target;
    setSelectedDays((prevDays) =>
      checked ? [...prevDays, value] : prevDays.filter((day) => day !== value)
    );
  };

  const handleRepeatChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setRepeat(e.target.checked);
  };

  const handleMonthChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setMonth(parseInt(e.target.value, 10));
  };

  const handleYearChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setYear(parseInt(e.target.value, 10));
  };

  const handleAddClass = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      const classBase = {
        instructor_id: newClass.instructor_id,
        dance_style_id: newClass.dance_style_id,
        dance_level_id: newClass.dance_level_id,
      };

      const startDate = newSchedule.start?.toDate();
      const endDate = newSchedule.end?.toDate();

      if (startDate && endDate) {
        if (repeat) {
          const schedules = generateSchedulesForMonth(startDate, endDate);
          const addClassPromises = schedules.map(async (schedule) => {
            const newClassDoc = await addDoc(
              collection(db, "classes"),
              classBase
            );
            await addDoc(
              collection(db, `classes/${newClassDoc.id}/schedules`),
              schedule
            );
          });
          await Promise.all(addClassPromises);
        } else {
          const newClassDoc = await addDoc(
            collection(db, "classes"),
            classBase
          );
          await addDoc(
            collection(db, `classes/${newClassDoc.id}/schedules`),
            newSchedule
          );
        }
      }

      fetchClasses(); // Re-fetch the classes after adding a new class
    } catch (error) {
      console.error("Error adding class:", error);
    }
  };

  const generateSchedulesForMonth = (startDate: Date, endDate: Date) => {
    const schedules: Partial<Schedule>[] = [];
    const monthStart = moment()
      .month(month - 1)
      .year(year)
      .startOf("month");
    const monthEnd = moment()
      .month(month - 1)
      .year(year)
      .endOf("month");

    selectedDays.forEach((day) => {
      let current = monthStart.clone().day(day);

      if (current.isBefore(monthStart)) {
        current.add(7, "days");
      }

      while (current.isSameOrBefore(monthEnd)) {
        const newStart = moment(current)
          .hour(startDate.getHours())
          .minute(startDate.getMinutes())
          .toDate();
        const newEnd = moment(current)
          .hour(endDate.getHours())
          .minute(endDate.getMinutes())
          .toDate();

        schedules.push({
          start: Timestamp.fromDate(newStart),
          end: Timestamp.fromDate(newEnd),
          dance_floor_id: newSchedule.dance_floor_id,
        });

        current.add(7, "days");
      }
    });

    return schedules;
  };

  const deleteClass = async (id: string) => {
    try {
      const classDoc = doc(db, "classes", id);
      const scheduleCollection = collection(db, `classes/${id}/schedules`);
      const scheduleSnapshot = await getDocs(scheduleCollection);

      // Delete all schedules associated with the class
      const deletePromises = scheduleSnapshot.docs.map((scheduleDoc) =>
        deleteDoc(scheduleDoc.ref)
      );
      await Promise.all(deletePromises);

      await deleteDoc(classDoc);
      fetchClasses(); // Re-fetch the classes after deletion
    } catch (error) {
      console.error("Error deleting class:", error);
    }
  };

  if (userRole !== "admin") {
    return <div>Access Denied</div>;
  }

  const showClassList = () => {
    const filteredClasses = classes.filter((classItem) => {
      const classNameMatch = classItem.name
        .toLowerCase()
        .includes(filterName.toLowerCase());
      const instructorMatch = (classItem.instructor ?? "")
        .toLowerCase()
        .includes(filterInstructor.toLowerCase());
      const danceFloorMatch = classItem.schedules?.some((schedule) =>
        schedule.danceFloor
          ?.toLowerCase()
          .includes(filterDanceFloor.toLowerCase())
      );
      const startDateMatch = filterStartDate
        ? classItem.schedules?.some(
            (schedule) =>
              new Date(schedule.start.toDate()) >= new Date(filterStartDate)
          )
        : true;
      const endDateMatch = filterEndDate
        ? classItem.schedules?.some(
            (schedule) =>
              new Date(schedule.end.toDate()) <= new Date(filterEndDate)
          )
        : true;

      return (
        classNameMatch &&
        instructorMatch &&
        danceFloorMatch &&
        startDateMatch &&
        endDateMatch
      );
    });

    return (
      <>
        <div className="filter-container text-white">
          <label htmlFor="filterName">Class Name:</label>
          <input
            type="text"
            id="filterName"
            value={filterName}
            onChange={(e) => setFilterName(e.target.value)}
          />
          <label htmlFor="filterInstructor">Instructor:</label>
          <input
            type="text"
            id="filterInstructor"
            value={filterInstructor}
            onChange={(e) => setFilterInstructor(e.target.value)}
          />
          <label htmlFor="filterDanceFloor">Dance Floor:</label>
          <input
            type="text"
            id="filterDanceFloor"
            value={filterDanceFloor}
            onChange={(e) => setFilterDanceFloor(e.target.value)}
          />
          <label htmlFor="filterStartDate">Start Date:</label>
          <input
            type="date"
            id="filterStartDate"
            value={filterStartDate}
            onChange={(e) => setFilterStartDate(e.target.value)}
          />
          <label htmlFor="filterEndDate">End Date:</label>
          <input
            type="date"
            id="filterEndDate"
            value={filterEndDate}
            onChange={(e) => setFilterEndDate(e.target.value)}
          />
        </div>
        <table className="text-white">
          <thead>
            <tr>
              <th>Name</th>
              <th>Instructor</th>
              <th>Dance Floor</th>
              <th>Start</th>
              <th>End</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {filteredClasses.map((classItem) =>
              classItem.schedules?.map((schedule) => (
                <tr key={schedule.id}>
                  <td>{classItem.name}</td>
                  <td>{classItem.instructor}</td>
                  <td>{schedule.danceFloor}</td>
                  <td>{new Date(schedule.start.toDate()).toLocaleString()}</td>
                  <td>{new Date(schedule.end.toDate()).toLocaleString()}</td>
                  <td>
                    <button onClick={() => deleteClass(classItem.id)}>
                      Delete
                    </button>
                  </td>
                </tr>
              ))
            )}
          </tbody>
        </table>
      </>
    );
  };

  const showCreateClasses = () => {
    return (
      <>
        <h2 className="text-title text-white">Create class</h2>
        <form onSubmit={handleAddClass}>
          <div className="text-white">
            <label htmlFor="dance_style_id">Dance Style</label>
            <select
              id="dance_style_id"
              name="dance_style_id"
              onChange={handleDanceStyleChange}
              required
            >
              <option value="">Select dance style</option>
              {danceStyles.map((style) => (
                <option key={style.id} value={style.id}>
                  {style.name}
                </option>
              ))}
            </select>
          </div>
          <div className="text-white">
            <label htmlFor="instructor_id">Instructor</label>
            <select
              id="instructor_id"
              name="instructor_id"
              value={newClass.instructor_id?.id || ""}
              onChange={handleInstructorChange}
              required
            >
              <option value="">Select Instructor</option>
              {instructors.map((instructor) => (
                <option key={instructor.id} value={instructor.id}>
                  {instructor.name.first} {instructor.name.last}
                </option>
              ))}
            </select>
          </div>

          <div className="text-white">
            <label htmlFor="dance_level_id"> Dance Level</label>
            <select
              id="dance_level_id"
              name="dance_level_id"
              onChange={handleDanceLevelChange}
              required
            >
              <option value="">Select Level</option>
              {danceLevels.map((level) => (
                <option key={level.id} value={level.id}>
                  {level.name}
                </option>
              ))}
            </select>
          </div>
          <div className="text-white">
            <label htmlFor="dance_floor_id">Dance Floor</label>
            <select
              id="dance_floor_id"
              name="dance_floor_id"
              onChange={handleDanceFloorChange}
              required
            >
              <option value="">Select Dance Floor</option>
              {danceFloors.map((floor) => (
                <option key={floor.id} value={floor.id}>
                  {floor.name}
                </option>
              ))}
            </select>
          </div>
          <div className="text-white">
            <label htmlFor="start">Start</label>
            <input
              type="datetime-local"
              id="start"
              name="start"
              value={
                newSchedule.start
                  ? moment(newSchedule.start.toDate())
                      .tz("America/Mexico_City")
                      .format("YYYY-MM-DDTHH:mm")
                  : ""
              }
              onChange={handleScheduleChange}
              required
            />
          </div>
          <div className="text-white">
            <label htmlFor="duration">Duration (minutes)</label>
            <select
              id="duration"
              name="duration"
              value={duration}
              onChange={handleScheduleChange}
              required
            >
              <option value={60}>60</option>
              <option value={90}>90</option>
              <option value={120}>120</option>
            </select>
          </div>
          <div className="text-white">
            <label>
              <input
                type="checkbox"
                checked={repeat}
                onChange={handleRepeatChange}
              />
              Repeat
            </label>
          </div>
          {repeat && (
            <>
              <div className="text-white">
                <label htmlFor="month">Month</label>
                <input
                  type="number"
                  id="month"
                  name="month"
                  value={month}
                  onChange={handleMonthChange}
                  min="1"
                  max="12"
                  required
                />
              </div>
              <div className="text-white">
                <label htmlFor="year">Year</label>
                <input
                  type="number"
                  id="year"
                  name="year"
                  value={year}
                  onChange={handleYearChange}
                  required
                />
              </div>
              <div className="text-white">
                <label>Repeat on:</label>
                {daysOfWeek.map((day) => (
                  <div key={day}>
                    <input
                      type="checkbox"
                      id={day}
                      name="days"
                      value={day}
                      onChange={handleDaysChange}
                    />
                    <label htmlFor={day}>{day}</label>
                  </div>
                ))}
              </div>
            </>
          )}

          <button style={{ color: "white" }} type="submit">
            Add Class
          </button>
        </form>
      </>
    );
  };

  return (
    <div className="wrapper">
      <section className="dashboard section-p-top bg-black" id="dashboard">
        <Navbar />
        <div className="container">
          <h1 className="section-t text-title">Admin Dashboard</h1>

          {showCreateClasses()}
          <h2 className="text-title text-white">Class List</h2>
          {showClassList()}
        </div>
      </section>
      <Footer />
    </div>
  );
};

export default AdminClasses;
