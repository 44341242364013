import React from "react";
import "./Portfolio.css";
import sections from "../../constants/data";

const Porfolio: React.FC = () => {
  return (
    <section className="portfolio section-p bg-dark" id="portfolio">
      <div className="container">
        <div className="portfolio-content">
          <div className="section-t text-center">
            <h3>Nuestro Portafolio</h3>
            <p className="text">
              Sumérgete en un mundo vibrante y diverso donde la expresión y el
              movimiento se entrelazan en una sinfonía de pasión y creatividad.
              En nuestro Portafolio de Bailes, te invitamos a explorar una
              amplia gama de estilos y géneros que representan la riqueza
              cultural y la expresión artística en su máxima expresión. Desde la
              elegancia clásica del ballet hasta la energía contagiosa del hip
              hop, cada baile es una ventana hacia una experiencia única y
              emocionante. Descubre la belleza atemporal del ballet, la
              sensualidad de la salsa, el ritmo vibrante del jazz, y mucho más.
              ¡Prepárate para dejarte llevar por el ritmo y la emoción en
              nuestro viaje a través del mundo del baile!
            </p>
          </div>
          <div className="item-list text-center text-black grid">
            {sections.portfolio.map((portfolio) => {
              return (
                <div
                  className="item flex flex-center flex-column translate-effect"
                  key={portfolio.id}
                >
                  <div
                    className="item-background"
                    style={{ backgroundImage: `url(${portfolio.image})` }}
                  ></div>
                  <div className="item-title fs-25 fw-6">{portfolio.title}</div>
                  <div className="text-black">{portfolio.text}</div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Porfolio;
