import React from "react";
import "./Qualities.css";
import sections from "../../constants/data";
import images from "../../constants/images";

const Qualities: React.FC = () => {
  return (
    <section className="qualities section-p bg-md-black" id="qualities">
      <div className="container">
        <div className="qualities-content grid">
          <div className="content-left">
            <div className="section-t text-center">
              <h3>Estilo profesional</h3>
              <p className="qualities-text">
                Nos enorgullece nuestro enfoque profesional y compromiso con la
                excelencia en la enseñanza del baile. Nuestro equipo de
                instructores altamente calificados no solo son expertos en sus
                respectivos estilos de baile, sino que también están dedicados a
                proporcionar una experiencia de aprendizaje enriquecedora y
                satisfactoria para cada uno de nuestros estudiantes. Desde la
                planificación de lecciones hasta la atención personalizada en el
                aula, nos esforzamos por mantener los más altos estándares de
                calidad en todo lo que hacemos.
              </p>
            </div>

            <div className="item-list grid text-white">
              {sections.qualities.map((quality) => {
                return (
                  <div className="item flex" key={quality.id}>
                    <div className="item-icon flex flex-center">
                      {quality.icon}
                    </div>
                    <div className="item-text">
                      <h3 className="item-title fs-25">{quality.title}</h3>
                      <p className="qualities-price">{quality.text}</p>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>

          <div className="content-right">
            <img src={images.professionals_main_img} alt="" />
          </div>
        </div>
      </div>
    </section>
  );
};

export default Qualities;
