import React from "react";
import "./Header.css";
import Navbar from "../Navbar/Navbar";
// import { FaPaperPlane } from "react-icons/fa";
import images from "../../constants/images";

const Header: React.FC = () => {
  return (
    <header className="header flex flex-center flex-column">
      <Navbar />
      <div className="container">
        <div className="header-content text-center flex flex-column">
          <div className="header-logo">
            <img src={images.emblema_img_2} alt="" />
          </div>

          {/* <img src={images.imagotipo_img_2} alt="" /> */}
          <p className="text-lead">Mundo Latino | Distintos tipos de baile</p>
          {/* <a href="/" className="btn header-btn btn-blue">
            <FaPaperPlane />
            <span>get started</span>
          </a> */}
        </div>
      </div>
    </header>
  );
};

export default Header;
