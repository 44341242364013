import { initializeApp } from "firebase/app";
import { getAuth, GoogleAuthProvider, onAuthStateChanged } from "firebase/auth";
import { getFirestore } from 'firebase/firestore';

const firebaseConfig = {
  apiKey: "AIzaSyAVsBr9n6c947e-i8mtjJzQo2ue5jQA5FM",
  authDomain: "ml-dance-studio-421800.firebaseapp.com",
  projectId: "ml-dance-studio-421800",
  storageBucket: "ml-dance-studio-421800.appspot.com",
  messagingSenderId: "419852858424",
  appId: "1:419852858424:web:8184cc5c03ffc6abb362cd",
  measurementId: "G-SWXJG81S5J",
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const googleProvider = new GoogleAuthProvider();
const db = getFirestore(app);

onAuthStateChanged(auth, (user) => {
  if (user !== null) {
    console.log("logged in");
  } else {
    console.log("No user");
  }
});

export { app, auth, googleProvider, db };
