import React, { useRef } from "react";
import sections from "../../constants/data";
import "./InfoCard.css";

const InfoCard: React.FC = () => {
  const phone = sections.contact[0];
  const text = sections.contact[1];
  const email = sections.contact[2];
  const address = sections.contact[3];

  const phoneRef = useRef(null);

  const handlePhoneCall = (phoneNumber: string) => {
    window.location.href = `tel:${phoneNumber}`;
  };

  const handleWhatsApp = (phoneNumber: string, message: string) => {
    // Use the WhatsApp API link with the phone number and message
    window.location.href = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(
      message
    )}`;
  };

  return (
    <div className="item bg-dark" key={phone.id}>
      <div className="contact-info-group">
        <span className="item-icon">{phone.icon}</span>
        <div className="info-details">
          <p className="fw-6 text-white">{phone.text}</p>
          <a
            ref={phoneRef}
            className="contact-info-group-ref"
            href={`tel:${phone.info}`}
            onClick={() => handlePhoneCall(phone.info)}
          >
            <div className="item-info-text text-grey">{phone.info}</div>
          </a>
        </div>
      </div>

      <div className="contact-info-group">
        <span className="item-icon">{text.icon}</span>
        <div className="info-details">
          <p className="fw-6 text-white">{text.text}</p>
          <a
            className="contact-info-group-ref"
            onClick={() =>
              handleWhatsApp(
                text.info,
                "¡Hola! Me gustaría recibir más información"
              )
            }
            // Add your WhatsApp number in the href attribute
            href={`https://wa.me/${text.info}`}
            target="_blank" // Open in a new tab/window
            rel="noopener noreferrer" // Security best practice
          >
            <div className="item-info-text text-grey">{phone.info}</div>
          </a>
        </div>
      </div>

      <a className="contact-info-group" href={`mailto:${email.info}`}>
        <span className="item-icon">{email.icon}</span>
        <div className="info-details">
          <p className="fw-6 text-white">{email.text}</p>
          <div className="item-info-text text-grey">{email.info}</div>
        </div>
      </a>

      <div className="contact-info-group">
        <span className="item-icon">{address.icon}</span>
        <div className="info-details">
          <p className="fw-6 text-white">{address.text}</p>
          <div className="item-info-text text-grey">{address.info}</div>
        </div>
      </div>
    </div>
  );
};

export default InfoCard;
