import React, { useEffect } from "react";
import "./Contact.css";
import images from "../../constants/images";
import Navbar from "../../components/Navbar/Navbar";
import Footer from "../../components/Footer/Footer";
import Map from "./Map";
import Info from "./Info";
import { useLocation } from "react-router-dom";

const Contact: React.FC = () => {
  const location = useLocation();

  useEffect(() => {
    if (location.hash) {
      const targetElement = document.querySelector(location.hash);
      if (targetElement) {
        targetElement.scrollIntoView({ behavior: "smooth" });
      }
    } else {
      // Scroll to the top of the page if no hash is provided
      window.scrollTo({ top: 0, behavior: "smooth" });
    }
  }, [location.hash]);

  return (
    <>
      <section className="contact section-p-top bg-black" id="contact">
        <Navbar />
        <div className="container">
          <div className="contact-content grid text-center">
            <div className="contact-left">
              <div className="section-t">
                <h3>Comunicate con nosotros</h3>
                <p className="text">
                  En ML Dance Studio, valoramos la comunicación abierta y
                  estamos aquí para responder cualquier pregunta que tengas. Si
                  deseas obtener más información sobre nuestras clases, horarios
                  o servicios, o si simplemente quieres compartir tus
                  comentarios y sugerencias, no dudes en contactarnos. Estamos
                  aquí para ayudarte y esperamos poder conectarnos contigo
                  pronto.
                </p>
              </div>
            </div>
            <div className="contact-right">
              <img src={images.form_main_img} alt="" />
            </div>
          </div>
        </div>
        <Map />
        <Info />
      </section>
      <Footer />
    </>
  );
};

export default Contact;
