import React from "react";
import images from "../../constants/images";
import "./About.css";

const About: React.FC = () => {
  return (
    <section className="about section-p bg-dark" id="about">
      <div className="container">
        <div className="about-content grid text-center">
          <div className="content-left">
            <img src={images.about_main_img} alt="" />
          </div>
          <div className="content-right">
            <div className="section-t">
              <h3>Acerca</h3>
            </div>
            <p className="text">
              En ML Dance Studio, nos enorgullece ofrecer un espacio dedicado a
              la pasión por el baile y la expresión artística. Como líderes en
              la comunidad del baile, brindamos clases en una variedad de
              estilos, tanto para individuos como para grupos, con la
              flexibilidad de horarios que se adapta a tus necesidades. Con un
              equipo de profesores altamente cualificados y apasionados, cada
              uno experto en su género, garantizamos una experiencia de
              aprendizaje divertida.
            </p>
            <br />
            <p className="text">
              Nuestras tres pistas de baile están diseñadas para ofrecerte el
              espacio perfecto para explorar y perfeccionar tus habilidades, ya
              sea que estés dando tus primeros pasos o buscando pulir tu
              técnica.
            </p>
            <br />
            <p className="text">
              En ML Dance Studio, no solo enseñamos pasos, sino que también
              cultivamos una comunidad de amantes del baile donde todos son
              bienvenidos a descubrir el gozo y la liberación que ofrece el arte
              del movimiento. Únete a nosotros y descubre tu propio ritmo en ML
              Dance Studio.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default About;
