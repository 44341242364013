import React from "react";
import "./PricingCard.css";

interface Pricing {
  id: number;
  name: string;
  price: string;
  time: string;
  image: string;
  content: string[];
}

const PricingCard: React.FC<Pricing> = ({
  id,
  image,
  name,
  price,
  time,
  content,
}) => {
  return (
    <>
      <div className="item translate-effect bg-dark" key={id}>
        <div className="item-img">
          <img src={image} alt="" />
        </div>
        <h4 className="item-name">{name}</h4>
        <br />
        <p className="item-price">{price}</p>
        <div className="content-time">
          <br />
          <h5>Periodo</h5>
          <p>{time}</p>
          <br />
        </div>
        <div className="content-list">
          <h5>Incluye:</h5>
          <ul>
            {content.map((item, index) => (
              <li key={index}>{item}</li>
            ))}
          </ul>
        </div>
      </div>
    </>
  );
};

export default PricingCard;
