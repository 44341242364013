import React, { useEffect, useState } from "react";
import { useAuth } from "../Auth/AuthProvider";
import { collection, getDocs } from "firebase/firestore";
import { db } from "../../services/firebaseConfig";
import Navbar from "../Navbar/Navbar";
import Footer from "../Footer/Footer";
import "./AdminDashboard.css";
import { Link } from "react-router-dom";

interface User {
  uid: string;
  name: string;
  email: string;
  role: string;
}

const AdminDashboard: React.FC = () => {
  const { userRole } = useAuth();
  const [users, setUsers] = useState<User[]>([]);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    if (userRole !== "admin") return;

    const fetchUsers = async () => {
      try {
        const userCollection = collection(db, "users");
        const userSnapshot = await getDocs(userCollection);
        const userList = userSnapshot.docs.map((doc) => ({
          uid: doc.id,
          ...doc.data(),
        })) as User[];
        setUsers(userList);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching users:", error);
        setLoading(false);
      }
    };

    fetchUsers();
  }, [userRole]);

  if (userRole !== "admin") {
    return <div>Access Denied</div>;
  }

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="wrapper">
      <section className="dashboard section-p-top bg-black" id="dashboard">
        <Navbar />
        <div className="container">
          <h1 className="section-t text-title">Admin Dashboard</h1>
          <h2 className="text-title text-white">User List</h2>
          <Link to="/admin/classes" className="btn btn-primary">
            Manage Classes
          </Link>
          <table className="text-white">
            <thead>
              <tr>
                <th>UID</th>
                <th>Name</th>
                <th>Email</th>
                <th>Role</th>
              </tr>
            </thead>
            <tbody>
              {users.map((user) => (
                <tr key={user.uid}>
                  <td>{user.uid}</td>
                  <td>{user.name}</td>
                  <td>{user.email}</td>
                  <td>{user.role}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </section>
      <Footer />
    </div>
  );
};

export default AdminDashboard;
