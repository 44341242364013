import React from "react";
import "./App.css";
import Header from "./components/Header/Header";
import "./assets/ts/script";
import Services from "./components/Services/Services";
import About from "./components/About/About";
import Qualities from "./components/Qualities/Qualities";
import Features from "./components/Features/Features";
import Porfolio from "./components/Portfolio/Porfolio";
import Testimonials from "./components/Testimonials/Testimonials";
import Footer from "./components/Footer/Footer";
import Video from "./components/Video/Video";

const App: React.FC = () => {
  return (
    <div className="App">
      <Header />
      <Video />
      <About />
      <Services />
      <Porfolio />
      <Qualities />
      <Testimonials />
      <Features />
      <Footer />
    </div>
  );
};

export default App;
