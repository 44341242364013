import React from "react";
import "./Services.css";
import sections from "../../constants/data";
import SingleService from "./SingleService";

const Services: React.FC = () => {
  return (
    <section className="services section-p bg-md-black" id="services">
      <div className="container">
        <div className="services-content">
          <svg width="1em" height="1em">
            <linearGradient
              id="blue-gradient"
              x1="100%"
              y1="100%"
              x2="0%"
              y2="0%"
            >
              <stop stopColor="#d08932" offset="0%" />
              <stop stopColor="#ffe15a" offset="100%" />
            </linearGradient>
          </svg>

          <div className="item-list grid text-white text-center">
            {sections.services.map((service) => {
              return (
                <SingleService
                  key={service.id}
                  id={service.id}
                  icon={service.icon}
                  title={service.title}
                  text={service.text}
                />
              );
            })}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Services;
