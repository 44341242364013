import React from "react";
import InfoCard from "../../components/Cards/InfoCard";

const Info: React.FC = () => {
  return (
    <div className="info bg-md-black section-p">
      <div className="container">
        <div className="info-content">
          <svg width="1em" height="1em">
            <linearGradient
              id="blue-gradient"
              x1="100%"
              y1="100%"
              x2="0%"
              y2="0%"
            >
              <stop stopColor="#d08932" offset="0%" />
              <stop stopColor="#ffe15a" offset="100%" />
            </linearGradient>
          </svg>
          <div className="item-list grid text-center">
            <InfoCard />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Info;
