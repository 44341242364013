import React from "react";
import "./Footer.css";
import { Link } from "react-router-dom";

const Footer: React.FC = () => {
  let date = new Date();
  return (
    <footer className="footer bg-black flex flex-center" id="footer">
      <div className="container flex flex-center w-100">
        <div className="grid footer-content text-center">
          <p className="text">
            Our services are available to all members of the public regardless
            of race, gender, or sexual orientation.
            <br />
            <span className="footer-links">
              <Link to="/privacy" className="link">
                Privacy Policy
              </Link>
              <span className="separator"> | </span>
              <Link to="/regulations" className="link">
                Regulations
              </Link>
            </span>
          </p>

          <span className="text">
            &copy; {date.getFullYear().toString()} ML Dance Studio® All Rights
            Reserved.
          </span>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
