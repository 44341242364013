import React, { useEffect } from "react";
import Footer from "../../components/Footer/Footer";
import Navbar from "../../components/Navbar/Navbar";
import sections from "../../constants/data";
import "./Privacy.css";
import { useLocation } from "react-router-dom";

interface PrivacyPolicySection {
  title: string;
  content: string;
}

interface PrivacyPolicy {
  title: string;
  address: string;
  email: string;
  sections: PrivacyPolicySection[];
  lastUpdated: string;
}

const Privacy: React.FC = () => {
  const location = useLocation();
  const privacy: PrivacyPolicy = sections.privacyPolicy;

  useEffect(() => {
    if (location.hash) {
      const targetElement = document.querySelector(location.hash);
      if (targetElement) {
        targetElement.scrollIntoView({ behavior: "smooth" });
      }
    } else {
      // Scroll to the top of the page if no hash is provided
      window.scrollTo({ top: 0, behavior: "smooth" });
    }
  }, [location.hash]);

  return (
    <>
      <div className="privacy section-p bg-dark" id="privacy">
        <Navbar />
        <div className="container">
          <h1 className="title text-grey text-center">
            {sections.privacyPolicy.title}
          </h1>
          <br />
          <p className="text text-justify">{sections.privacyPolicy.content}</p>
          <br />

          <ul>
            {privacy.sections.map((section, index) => (
              <li key={index}>
                <h2 className="section-content text-grey">{section.title}</h2>
                <p className="text text-justify">{section.content}</p>
              </li>
            ))}
          </ul>
          <br />
          <p className="text text-end">{privacy.lastUpdated}</p>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Privacy;
