import React, { useState } from "react";
import { useAuth } from "./AuthProvider";
// import { useNavigate } from "react-router-dom";
import Navbar from "../Navbar/Navbar";
import Footer from "../Footer/Footer";

const Login: React.FC = () => {
  // const [email, setEmail] = useState("");
  // const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const { loginWithGoogle, logout } = useAuth();
  // const navigate = useNavigate();
  const { currentUser } = useAuth();

  const handleGoogleSignIn = async () => {
    try {
      await loginWithGoogle();
      // navigate("/");
    } catch (err) {
      setError("Failed to log in with Google");
    }
  };

  const handleLogout = async () => {
    try {
      await logout();
    } catch (err) {
      setError("Failed to log out");
    }
  };

  return (
    <div className="wrapper">
      <section className="contact section-p-top bg-black" id="contact">
        <Navbar />
        <div className="container">
          <h2 className="section-t text-title text-center">Login</h2>
          <h2 className="text">User Profile</h2>
          <p className="text">
            Name: {currentUser ? currentUser?.displayName : "No user"}
          </p>
          <p className="text">
            Email: {currentUser ? currentUser?.email : "No email"}
          </p>
          {error && <p>{error}</p>}
          {!currentUser && (
            <button onClick={handleGoogleSignIn}>Log In with Google</button>
          )}

          {currentUser && (
            <button className="text-white" onClick={handleLogout}>
              Log Out
            </button>
          )}
        </div>
      </section>
      <Footer />
    </div>
  );
};

export default Login;
